@font-face {
  font-family: "Ebrima";
  src: url("./font/ebrima.ttf");
}

@font-face {
  font-family: "Ebrima-Bold";
  src: url("./font/ebrimabd.ttf");
}

@font-face {
  font-family: "Cabin-Bold";
  src: url("./font/cabin.ttf");
}

:root {
  margin: 0;
  padding: 0;
  --body-fonts: "Ebrima", sans-serif !important;
}
body {
  font-family: var(--body-fonts);
  overflow-x: hidden !important;
}

body,
html {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--body-fonts);
}

p {
  font-family: var(--body-fonts);
}

h1 h2 h3 h4 h5 h6 {
  font-family: var(--body-fonts);
}

ul li {
  list-style: none;
  text-decoration: none;
  font-family: var(--body-fonts);
}

Link {
  all: unset;
}

button {
  background: linear-gradient(
    266.04deg,
    #23e42b -33.91%,
    #808080 53.31%,
    #cd23e4 146.94%
  );
  border-radius: 50px;
  width: 100%;
  padding: 20px 0;
  text-align: center;
  color: #fff;
  font-size: 20px;
}

.App {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  max-width: 100vw;
  overflow-x: hidden !important;
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
}

.page-bg-light {
  background-image: url('./lines/page-light.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  max-width: 100vw;
  overflow-x: hidden !important;
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
  background-position: center center; 
}

.page-bg-dark {
  background-image: url('./lines/page-dark.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  max-width: 100vw;
  overflow-x: hidden !important;
  margin: 0 auto;
  padding: 0;
  min-height: 100vh;
  background-position: center center; 
}
.text_outline_black {
  -webkit-text-stroke: 1px black;
}

.text_outline_white {
  -webkit-text-stroke: 1px white;
}

.box_outline {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right, #ffd700, #ff8c00, #ff1493) 1;
  border-radius: 30px;
}

.element-blur {
  backdrop-filter: blur(12px);
}

.spotlight-slider {
  max-width: 98.5rem;
  width: 100%;
}

/* Login Page style */
.login-border {
  border-width: 2px;
  border-style: solid;
  border-color: linear-gradient(to right, #23e42b, #808080, #cd23e4);
  border-radius: 30px;
}

.border-gradient {
  border: 3px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-radius: 30px;
}

.border-gradient-purple {
  border-image-source: linear-gradient(to right, #23e42b, #808080, #cd23e4);
}

.scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Blog Page Style */
.blog-card {
  box-shadow: 10px 20px 30px -10px rgba(21, 21, 21, 0.15);
}

.blog-list {
  background: linear-gradient(
    266.04deg,
    #23e42b -33.91%,
    #808080 53.31%,
    #cd23e4 146.94%
  );
  color: #fff;
  border-radius: 50px;
}

.view-post {
  background: linear-gradient(
    266.04deg,
    #23e42b -33.91%,
    #808080 53.31%,
    #cd23e4 146.94%
  );
  color: #fff;
  border-radius: 50px;
}

.slider.animated .slide {
  padding: 50px 0;
}

.carousel-status {
  display: none;
}

.control-dots .dot {
  width: 2.4rem;
  height: 2.4rem;
  color: red !important;
}

.carousel .control-dots .dot {
  box-shadow: none;
  background: #cecece;
}

.carousel .control-dots .dot.selected {
  background: black;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.btn-top {
  top: calc(50% - 300px);
}

.cat-card {
  box-shadow: 10px 20px 30px -10px rgba(21, 21, 21, 0.15);
}

.card-image {
  background: linear-gradient(103.36deg, #23e42b 3.53%, #195bff 99.19%);
  border-radius: 30px 30px 0px 0px;
}

.card-list {
  background: linear-gradient(
    266.04deg,
    #23e42b -33.91%,
    #808080 53.31%,
    #cd23e4 146.94%
  );
  border-radius: 50px;
}

@media (max-width: 768px) {
  .card-image {
    border-radius: 20px 20px 0px 0px;
  }
}
.sect {
}
.section-area-hero {
  padding-bottom: 100px;
  min-height: 55vh;

  position: relative;
}

.section-area-hero::after {
  background: url("./img/prop1.svg") no-repeat;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: -170px;
  right: -80%;
  z-index: -1;
}

.section-area-hero::before {
  background: url("./img/prop2.svg") no-repeat;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 190px;
  left: -90px;
  z-index: -1;
}

.card-1 {
  top: 30.125rem;
  right: -2.375rem;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

.card-2 {
  top: -1rem;
  left: 16rem;
  -webkit-transform: rotate(19deg);
  -moz-transform: rotate(19deg);
  -o-transform: rotate(19deg);
  -ms-transform: rotate(19deg);
  transform: rotate(12deg);
}

.item-1 {
  top: 27rem;
  left: 30rem;
  position: absolute;
}

.item-2 {
  top: 25rem;
  left: 8rem;
  position: absolute;
}

.item-3 {
  top: 20rem;
  left: -1rem;
  position: absolute;
}

.item-4 {
  top: -4rem;
  left: -2rem;
  position: absolute;
}

.item-5 {
  top: -10rem;
  left: 12rem;
  position: absolute;
}

.item-6 {
  top: -9rem;
  right: 4rem;
  position: absolute;
}

.sidebar {
  width: 65px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 30;
  /* transform: rotate(-90deg); */
  display: flex;
  align-items: center;
}
/* .sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 100%;
} */

.sidebar-text-white {
  -webkit-text-stroke: 1px #221f3b;
  color: transparent;
}

.sidebar-text-black {
  -webkit-text-stroke: 1px white;
  color: black;
}

.marquee {
  /* width: 100%; */
  overflow: hidden;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.rot {
  transform: rotate(-90deg);
  position: relative;
}

.trending-collection::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: -600px;
  right: -14%;
  z-index: 1;
}

.trending-collection::after {
  background: url("./img/side-line.svg") no-repeat;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: -190px;
  left: 0;
  z-index: 1;
}

.about-collection {
  z-index: 1;
}
.about-collection::before {
  background: url("./img/about-lines2.svg") no-repeat;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0;
  z-index: -1;
}

.about-collection::after {
  background: url("./img/about-lines2.svg") no-repeat;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 40%;
  left: 0;
  z-index: 1;
}

/* React Slick */

.slick-track .slick-slide {
  max-width: 400px !important;
}

@media (max-width: 768px) {
  .slick-track .slick-slide {
    max-width: 280px !important;
  }
  .slick-list {
    padding-left: 0px !important;
  }
  .trending-collection::after {
    background: url("./img/side-line.svg") no-repeat;
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: -470px;
    left: 0;
    z-index: 1;
  }

  .section-area-hero::before {
    background: url("./img/prop2.svg") no-repeat;
    position: absolute;
    content: "";
    height: 230px;
    width: 230px;
    background-size: contain;
    top: 150px;
    left: -30px;
    z-index: -1;
  }

  .section-area-hero::after {
    background: url("./img/prop1.svg") no-repeat;
    position: absolute;
    content: "";
    height: 230px;
    width: 230px;
    background-size: contain;
    top: -170px;
    right: -30px;
    z-index: -1;
  }

  .card-1 {
    top: 30.125rem;
    left: -2.4rem;
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  .card-2 {
    top: -0.6rem;
    left: 11rem;
    -webkit-transform: rotate(19deg);
    -moz-transform: rotate(19deg);
    -o-transform: rotate(19deg);
    -ms-transform: rotate(19deg);
    transform: rotate(12deg);
  }

  .item-4 {
    top: -2rem;
    left: 0.8rem;
    position: absolute;
  }

  .item-5 {
    top: -6rem;
    left: 8.5rem;
    position: absolute;
  }

  .item-6 {
    top: -5rem;
    right: 0.5rem;
    position: absolute;
  }

  .item-3 {
    top: 14rem;
    left: 1rem;
    position: absolute;
  }

  .item-2 {
    top: 16rem;
    left: 8rem;
    position: absolute;
  }

  .item-1 {
    top: 17rem;
    left: 18rem;
    position: absolute;
  }

  .loader {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    animation: animate 2s linear infinite;
  }
}

@media (max-width: 360px) {
  .card-1 {
    top: 30.125rem;
    left: -2.4rem;
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    -o-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-10deg);
    height: 230px;
    width: 165px;
  }

  .card-2 {
    top: -0.4rem;
    left: 10rem;
    -webkit-transform: rotate(19deg);
    -moz-transform: rotate(19deg);
    -o-transform: rotate(19deg);
    -ms-transform: rotate(19deg);
    transform: rotate(11deg);
    height: 270px;
    width: 190px;
  }

  .item-3 {
    top: 11rem;
    left: 1rem;
    position: absolute;
  }

  .item-1 {
    top: 16rem;
    left: 17rem;
    position: absolute;
  }
}

.z-site {
  z-index: 999999;
}

.close {
  visibility: hidden;
}

.fadeOut {
  opacity: 0;
  transition: all 0.2s ease-in;
}

.preloader {
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  min-height: 102px;
  transform: translateX(-50%) translateY(-50%);
}
.preloader .small-circle {
  stroke-dasharray: 210;
  stroke-dashoffset: 210;
  transform-origin: 50%;
  animation: 1s draw-small infinite alternate;
}
@keyframes draw-small {
  0% {
    stroke-dashoffset: 0;
    transform: rotate(0deg);
  }
  100% {
    stroke-dashoffset: 210;
    transform: rotate(360deg);
  }
}
.preloader .big-circle {
  stroke-dasharray: 240;
  stroke-dashoffset: 240;
  transform-origin: 50%;
  animation: 1s draw-big infinite alternate 0.5s;
}
@keyframes draw-big {
  0% {
    stroke-dashoffset: 0;
    transform: rotateY(180deg) rotate(360deg);
  }
  100% {
    stroke-dashoffset: 240;
    transform: rotateY(180deg) rotate(0deg);
  }
}

/* @media (max-width: 639px) {
    .bg-mobile {
      background-image: url('path/to/mobile-bg-light.jpg');
    }
    .dark .bg-mobile-dark {
      background-image: url('path/to/mobile-bg-dark.jpg');
    }
  } */
@media (min-width: 640px) {
  .bg-desktop {
    background-image: url("./img/line-white-desktop.svg");
  }
  .dark .bg-desktop-dark {
    background-image: url("./img/line-dark-desktop.svg");
  }
}

.c-list {
  display: flex;
  overflow-x: hidden;
  justify-content: flex-start;
  position: absolute;
  width: 100vw;
  height: 100%;
  transform: rotate(-90deg);
  top: 0;
  padding-bottom: 1rem;
}

.content {
  min-width: fit-content;
  position: relative;
  animation: move 40s linear infinite;
}

@keyframes move {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
.line-bg {
  background: url("./lines/line_bg.svg");
  background-size: cover;
  background-position: top;
}
